.login-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.login-card {
    width: 320px;
    label{
        width: 65px;
        text-align: right;
        display: inline-block;
        vertical-align: -4px;
    }
    .btn-box{
        text-align: right;
    }
}
@primary-color: #1DA57A;