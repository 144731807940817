.backrooms{
    margin: 24px;
    min-height: 100vh;
    .levelButton{
        margin-right: 5px;
        margin-bottom: 5px;
        width: 160px;
        overflow: hidden;
        .levelBox{
            display: flex;
        }
        img{
            width: 23px;
            height: auto;
            margin-right: 2px;
        }
        .rate{
            display: inline-block;
            font-size: 12px;
            text-align: center;
            width: 25px;
        }
        .no{
            text-align: center;
            font-weight: bold;
            font-size: 10px;
        }
        .name{
            margin-left: 65px;
        }
    }
}

.fight-simulator{

    .selected-teams{

        display: flex;
        justify-content: space-around;
        .team{
            .role{
                
                margin: 20px 20px 20px 0;
                
            }
        }
    }
    .results{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .rounds{
            display: flex;

            .team{
                border: 2px solid #808080;
                display: flex;
                flex-direction: row;
                margin: 10px;
                &.attack{
                    border-color: green;
                }
                &.defense{
                    border-color: red;
                }
                .roles{
                    margin: 10px;
                }

                .attackRole, .defenseRole{
                    font-weight: bold;
                }
            }

        }
    }
}

@primary-color: #1DA57A;