.common-content{
    margin: 24px;
    min-height: 100vh;
    background-color: #ffffff;
}

.gjp-location{
    .p{
        width: 100%;
        display: flex;
        margin-top: 24px;
        .lo{
            flex: 1;
            align-items: center;
            display: flex;
            flex-direction: column;
            .face{
                flex:1;
                height: 200px;
                display: flex;
                flex-direction: column;
                width: 90%;
                &.A{
                    //flex-flow: column-reverse;
                }
                &.B{
                    margin-top: 24px;
                }
                .layer{
                    flex:1;
                    align-items: center;
                    justify-content: center;
                    background: rgb(165, 165, 165);
                    text-align: center;
                    margin-top: 2px;
                    color: #fff;
                    &.available{
                        background: greenyellow;
                        color:#000;
                    }
                    &.wrong{
                        background-color: red;
                        color: #fff;
                    }
                }
            }
        }
    }
}
@primary-color: #1DA57A;