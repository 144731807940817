.jd-products-content {
    margin: 24px;
    min-height: 100vh;
    background-color: #ffffff;
}

.jd-products-print {
    display: none;
    margin: 0px;
    box-sizing: border-box;
}

.print {

    font-size: 0;

    &.square {
        margin: 0 auto;
        width: 70mm;
        overflow: hidden;
    }

    .single {
        font-size: 12px;
        width: 70mm;
        height: 70mm;
        box-sizing: border-box;
        overflow: hidden;
        display: flex;
        page-break-after: always;
        box-sizing: border-box;
        .title{
            white-space: nowrap;
        }
        .address{
            font-size: 12px;
        }
        .space {
            justify-content: center;
            width: 100%;
        }
    }
}

@media print {

    html {
        margin: 0cm !important;
        padding: 0cm !important;
    }

    @page {
        margin: 0cm !important;
        padding: 0cm !important;
        width: 80mm;
        height: 80mm;
    }

    .ant-card-body{
        padding: 0 !important;
    }

    .ant-layout-sider {
        display: none;
    }

    .jd-products-content {
        display: none;
    }

    .jd-products-print {
        display: block;
    }


}
@primary-color: #1DA57A;