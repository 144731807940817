
.skibidiToiletContent{
    padding: 10px;
    .cardContent{
        padding: 30px;
    }

    .teamView{
        &.active{
            border-left: 5px solid #000;
        }
    }
    .multiGroupTestResult{
        font-size: 12px;
        .scriptId{
            width: 100px;
            display: inline-block;
            font-weight: bold;
        }
    }

    .card{
        display: inline-block;
        border: 1px solid #999;
        height: auto;
        width: 120px;
        margin:3px;
        vertical-align: top;
        padding: 5px;
        font-size: 12px;

        h3{
            font-size: 12px;
        }

        img{
            max-width: 100%;
            height: auto;
        }
    }

    .compoundImage{
        display: block;
        width: 300px;
        height: 300px;
    }

    .teams{
        .team{
            display: inline-block;
            padding: 5px;
            margin: 5px;
            border: 1px solid #000;
            &.win{
                border: 2px solid rgb(29, 190, 69);
            }
            &.lose{
                border:  2px solid rgb(202, 48, 48);
            }
            &.standoff{
                border:  2px solid rgb(180, 49, 241);
            }
            &.testing{
                border: 2px dashed #000;
                background: #888;
            }
            .heroId{
                display: inline-block;
                width: 50px;
                overflow: hidden;
                margin: 5px;

            }
        }
    }

    .fightRate{
        color:#000;
        margin: 0 10px;
        .rateHolder{
            width: 200px;
            display: inline-block;
            position: relative;
        }
        .rateValue{
            position: relative;
            z-index: 1;
        }
        .rateBar{
            padding: 5px;
            display: inline-block;
            position:absolute;
            left: 0;
            bottom: 0;
            height: 10px;
        }
        .winRate{
            background: rgb(29, 190, 69);
        }
        .standoffRate{
            background: rgb(180, 49, 241);
        }
        .loseRate{
            background: rgb(202, 48, 48);
        }
    }


    .heroCard{
        margin: 10px;
        display: inline-block;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);

        padding: 5px;
        color: white;
        background: black;

        vertical-align: top;
        .hpValue{
            display: inline-block;
            padding: 0 5px;
            background: rgb(250, 81, 81);
            color: #fff;
        }
        .defenseValue{
            display: inline-block;
            padding: 0 5px;
            background: rgb(60, 112, 255);
            color: #fff;
        }
    }

    .steps{
        font-size: 20px;
    }

    .points{
        font-size: 20px;
    }

    .buffSkill{
        cursor: pointer;
        display: inline-block;
        border: 1px solid #000;
        margin: 10px;
        padding: 10px;
        text-align: left;
        .description{
            font-size: 10px;
            width: 100px;
        }
        .attackValue{
            display: inline-block;
            padding: 0 5px;
            background: rgb(250, 81, 81);
            color: #fff;
        }
        .defenseValue{
            display: inline-block;
            padding: 0 5px;
            background: rgb(60, 112, 255);
            color: #fff;
        }
        > span{
            display: block;
        }
    }
    .buffContainer{
        perspective: 1000px;
        .buff{
            transition: transform 300ms;
            backface-visibility: hidden;
            display: inline-block;
            border: 1px solid #fff;
            margin: 2px;
            padding: 2px;
            position: relative;
            .buffName{
                word-break: keep-all;
                padding: 0 10px;
            }
            .buffState{
                position: absolute;
                left: 0;
                padding: 2px;
                bottom: -8px;
                line-height: 100%;
                background: #000;
            }
            > span{
                display: block;
            }
        }
    }




    p{
        font-size: 8px;
    }
}


@primary-color: #1DA57A;