.jd-products-content {
    margin: 24px;
    min-height: 100vh;
    background-color: #ffffff;
}


.btn-show-modal {
    margin: 24px;
}
.qr-content{
    position: fixed;
    width: 90vw;
    height: 300px;
    left: 5vw;
    top: 100px;
    background-color: #FFF;
    padding: 24px;
    box-sizing: border-box;
    &.close{
        display: none;
    }
}
.qr-area {
    display: flex;
    width: 100%;
    background-color:rgb(250, 219, 79) ;
    border-radius: 10px;
    margin-top: 24px;
    .model-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-height: 200px;
        max-width: 180px;
        padding:18px 0;
        img {
            width: 100px;
            background-color: #FFF;
            border-radius: 4px;
        }
        p{
            background-color: gray;
            color: #FFF;
            border-radius: 4px;
            padding: 0 4px;
            max-width: 100px;
            overflow: hidden;
            white-space:nowrap;
        }
    }

    .qrcode {
        background-color: #FFF;
        padding: 8px;
        border-radius: 4px;
        margin: 18px;
        width: 136px !important;
        height: 136px !important;
    }
}
@primary-color: #1DA57A;