.usecubes-all-star{
    margin: 24px;
    min-height: 100vh;

    .roleButton{
        margin-right: 5px;
        margin-bottom: 5px;
        width: 140px;
        overflow: hidden;
        text-align: left;
        padding: 0 5px;
        .rate{
            width: 30px;
            display: inline-block;
            font-size: 10px;
            line-height: 100%;
            padding: 2px;
            text-align: center;
            background: #808080;
            color: #fff;
            border-radius: 2px;
            position: absolute;
            top: 2px;
            font-weight: bold;
        }
        .no{
            position: absolute;
            left: 5px;
            width: 30px;
            text-align: center;
            bottom: 0px;
            font-weight: bold;
            font-size: 10px;
            transform: scale(0.8);
            line-height: 100%;

        }
        .name{
            margin-left: 35px;
            display: inline-block;
        }
        .more{
            display: inline-block;
        }

        &.withId{
            width: auto;
        }
    }
    .all-star-category{
        .br{
            height: 30px;
        }
    }
    .fight-simulator{

        .selected-teams{

            display: flex;
            justify-content: space-around;
            .team{
                .role{
                    
                    margin: 20px 20px 20px 0;
                    
                }
            }
        }
        .results{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .rounds{
                display: flex;

                .team{
                    border: 2px solid #808080;
                    display: flex;
                    flex-direction: row;
                    margin: 10px;
                    &.attack{
                        border-color: green;
                    }
                    &.defense{
                        border-color: red;
                    }
                    .roles{
                        margin: 10px;
                    }

                    .attackRole, .defenseRole{
                        font-weight: bold;
                    }
                }

            }
        }
    }
}

@primary-color: #1DA57A;